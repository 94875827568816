// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
import logo from '../assets/images/logo.png';
import { Box } from '@mui/system';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *import { Link } from 'react-router-dom';

 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            <Typography variant={matchDownSM ? 'h3' : 'h2'}>
                <Box>
                    <img src={logo} alt="" width="140px"></img>
                </Box>
            </Typography>
        </>
    );
};

export default Logo;
