import { AuthContext } from 'authProvider/AuthProvider';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
const PrivateRoute = ({ children }) => {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);

    if (!user) {
        return <Navigate to="/login" state={{ from: location }}></Navigate>;
    }

    return children;
};

export default PrivateRoute;
