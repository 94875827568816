import React, { useRef, useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// SweetAlert
import swal from 'sweetalert';
// toastify
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// project imports
import NavigationScroll from 'layout/NavigationScroll';
import AuthProvider, { AuthContext } from 'authProvider/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { IdleTimerProvider } from 'react-idle-timer';
// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();
    const ref = useRef();
    function handleOnAction(event) {
        // console.log('user did something', event);
    }

    function handleOnActive(event) {
        // console.log('user is active', event);
        // console.log("time remaining", this.idleTimer.getRemainingTime());
    }

    function handleOnIdle(event) {
        // console.log('logout');
        localStorage.removeItem('userInfo');
        navigate('/login');
    }

    return (
        <Box>
            <AuthProvider>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={themes(customization)}>
                        <CssBaseline />
                        <NavigationScroll>
                            <IdleTimerProvider
                                ref={ref}
                                timeout={300000}
                                onActive={handleOnActive}
                                onIdle={handleOnIdle}
                                onAction={handleOnAction}
                                debounce={2500}
                            />

                            <Routes />
                        </NavigationScroll>
                    </ThemeProvider>
                </StyledEngineProvider>
            </AuthProvider>
            <ToastContainer />
        </Box>
    );
};

export default App;
