// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconTableImport } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconTableImport
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    // title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'company',
            title: 'Company',
            type: 'item',
            url: '/company',
            icon: icons.IconTypography,
            breadcrumbs: false
        },
        // {
        //     id: 'util-color',
        //     title: 'Color',
        //     type: 'item',
        //     url: '/utils/util-color',
        //     icon: icons.IconPalette,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'util-shadow',
        //     title: 'Shadow',
        //     type: 'item',
        //     url: '/utils/util-shadow',
        //     icon: icons.IconShadow,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'icons',
        //     title: 'Icons',
        //     type: 'collapse',
        //     icon: icons.IconWindmill,
        //     children: [
        //         {
        //             id: 'tabler-icons',
        //             title: 'Tabler Icons',
        //             type: 'item',
        //             url: '/icons/tabler-icons',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'material-icons',
        //             title: 'Material Icons',
        //             type: 'item',
        //             url: '/icons/material-icons',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        {
            id: 'bill',
            title: 'Bill ',
            type: 'collapse',
            icon: icons.IconTableImport,
            children: [
                {
                    id: 'add-bill',
                    title: 'Add Bill',
                    type: 'item',
                    url: '/add-bill',
                    breadcrumbs: false
                },
                {
                    id: 'bill-management',
                    title: 'Bill List',
                    type: 'item',
                    url: '/bill-list',
                    breadcrumbs: false
                },
                {
                    id: 'bill-management',
                    title: ' Dues Bill',
                    type: 'item',
                    url: '/bill-dues',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default utilities;
