import React, { createContext, useState } from 'react';
const local = JSON.parse(localStorage.getItem('userInfo'));
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(local);
    const [company, setCompany] = useState([]);
    const [update, setUpdate] = useState([]);
    // console.log(user.name);
    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                company,
                setCompany,
                update,
                setUpdate
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
export default AuthProvider;
