import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { useState, useEffect } from 'react';
import PrivateRoute from 'Route/PrivateRoute';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsCompany = Loadable(lazy(() => import('views/utilities/addCompany/Company')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const UtilsMaterialTables = Loadable(lazy(() => import('views/utilities/billManagement/BillManagement')));
const UtilsTablerTables = Loadable(lazy(() => import('views/utilities/billManagement/AddBill')));
const UtilsDuesTables = Loadable(lazy(() => import('views/utilities/billManagement/BillDues')));
const NotFoundPage = Loadable(lazy(() => import('views/utilities/NotFoundPage')));

// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: (
        <PrivateRoute>
            <MainLayout />
        </PrivateRoute>
    ),

    children: [
        {
            path: '/',
            element: (
                <PrivateRoute>
                    <DashboardDefault />
                </PrivateRoute>
            )
        },

        {
            path: '/dashboard',
            element: (
                <PrivateRoute>
                    <DashboardDefault />
                </PrivateRoute>
            )
        },
        {
            path: '/company',
            element: <UtilsCompany />
        },
        // {
        //     children: [
        //         {
        //             path: 'util-color',
        //             element: <UtilsColor />
        //         }
        //     ]
        // },
        // {
        //     children: [
        //         {
        //             path: 'util-shadow',
        //             element: <UtilsShadow />
        //         }
        //     ]
        // },
        // {
        //     path: 'tabler-icons',
        //     element: <UtilsTablerIcons />
        // },
        // {
        //     path: 'material-icons',
        //     element: <UtilsMaterialIcons />
        // },
        {
            path: '/add-bill',
            element: <UtilsTablerTables />
        },
        {
            path: '/bill-list',
            element: <UtilsMaterialTables />
        },
        {
            path: '/bill-dues',
            element: <UtilsDuesTables />
        },
        {
            path: '*',
            element: <NotFoundPage />
        }
        // {
        //     path: 'sample-page',
        //     element: <SamplePage />
        // }
    ]
};

export default MainRoutes;
